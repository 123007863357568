<template>
  <nav id="sidebarMenu" class="new-sidebar">
    <div class="logo-wrapper" v-if="$vuetify.breakpoint.width > 1100">
      <a href="/" class="navbar-brand">
        <span>
          <img src="./../../assets/img/redline-new-logo.svg" alt="Redlineusedautoparts Logo" />
        </span>
      </a>
    </div>
    <div class="sidebar-wrap">
      <div class="sidebar-up">
        <ul class="navbar-nav main-nav">
          <li v-if="user.user_type != 3" @click="removeBodyClass" v-for="item in filteredMenus" :key="item.title"
            class="nav-item" :class="{ [item.link.split('/').pop()]: true }">
            <router-link :to="item.link" class="nav-link">
              <div class="img-wrap">
                <img :src="item.img" alt="Menu Icon" />
              </div>
              <span class="menu-title">{{ $t(item.title) }}</span>
            </router-link>
          </li>
          <li v-if="user.user_type != 3" class="nav-item">
            <a :href="websiteServicesLink" target="_blank" class="nav-link">
              <div class="img-wrap">
                <img src="./../../assets/img/website-black.svg" alt="Website Services Icon" />
              </div>
              <span class="menu-title">{{ $t("navbar.websiteServices") }}</span>
            </a>
          </li>
          <li v-if="user.user_type != 3" class="nav-item">
            <a :href=" inventoryUrl + `?auth_details_user=${user.id}`" target="_blank" class="nav-link">
              <div class="img-wrap">
                <img src="./../../assets/img/logout-black.svg" alt="Inventory Login Icon" />
              </div>
              <span class="menu-title">{{ $t("navbar.inventoryLogin") }}</span>
            </a>
          </li>

          <!-- Menus for user type 3 -->
          <li v-for="item in user.user_type === 3 ? user3filteredMenus : []" :key="item.title" class="nav-item"
            @click="removeBodyClass">
            <router-link v-if="item.title.toLowerCase() !== 'navbar.carhistoryreport'" :to=item.link class="nav-link">
              <div class="img-wrap">
                <img :src="item.img" alt="" />
              </div>
              <span class="menu-title" v-html="$t(item.title)"></span>
            </router-link>

            <a :href="`${item.link}`" v-if="item.title.toLowerCase() === 'navbar.carhistoryreport'" target="_blank"
              class="nav-link">
              <div class="img-wrap">
                <img :src="item.img" alt="" />
              </div>
              <span class="menu-title" v-html="$t(item.title)"></span>
            </a>
          </li>
        </ul>
      </div>
      <div class="sidebar-bottom">

        <v-autocomplete class="room-select" outlined v-model="room" :items="roomList" @change="changeRoom"
          item-text="name" item-value="code" :label="$t('navbar.room')" return-value></v-autocomplete>
        <div class="need-assitance">
          <a class="customBtn pattern-btn blue-ptn" href="tel:+1 714 334 5505">
            <span>
              <img src="./../../assets/img/icon-phone-call.svg" />
              {{ $t("navbar.helpCall") }}
              <b>714-334-5505</b>
            </span>
          </a>
        </div>
        <ul class="navbar-nav main-logout">
          <a href="javascript:void(0)" @click="doLogout" class="btn customBtn pattern-btn w-100" class-active="">
            <span class="custom-flex">
              <div class="img-wrap">
                <img class="btn-log-out-img" src="./../../assets/img/logout-new.svg" alt="" />
              </div>
              {{ $t("navbar.logOut") }}
              <img src="./../../assets/img/right-arrow.svg" alt="">
            </span>
          </a>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import SocketMixin from "@/mixins/SocketMixin";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Sidebar",
  mixins: [SocketMixin.listeners],
  data() {
    return {
      room: "",
      lang: localStorage.getItem("lang"),
      sideNav: null,
      menus: [
        {
          title: "navbar.membersDirectory",
          link: "/dashboard/",
          img: require('@/assets/img/members.svg'),
          mobileOnly: true,
        },
        {
          title: "navbar.carHistory",
          link: "/dashboard/vehicle-history",
          img: require('@/assets/img/car-history.svg'),
          mobileOnly: true,
        },
        {
          title: "navbar.interchange",
          link: "/dashboard/interchange",
          img: require('@/assets/img/interchange-1.svg'),
          mobileOnly: true,
        },
        {
          title: "navbar.inventory",
          link: "/dashboard/serach-inventory",
          img: require('@/assets/img/car-with-parts.svg'),
          mobileOnly: true,
        },
        {
          title: "navbar.oInvoice",
          link: "/dashboard/billing-invoice",
          img: require('@/assets/img/invoice.svg'),
          mobileOnly: true,
        },
        {
          title: "navbar.booklog",
          link: "/dashboard/booklog",
          img: require('@/assets/img/solar_book-linear.svg'),
          mobileOnly: false,
        },
        {
          title: "navbar.marketingCampaign",
          link: "/dashboard/marketing-campaign",
          img: require('@/assets/img/hugeicons_marketing.svg'),
          mobileOnly: false,
        },
        {
          title: "navbar.hotlineNews",
          link: "/dashboard/hotline-news",
          img: require('@/assets/img/mingcute_news-line.svg'),
          mobileOnly: false,
        },
        {
          title: "navbar.coreBuyingMember",
          link: "/core-buying/",
          img: require('@/assets/img/tabler_recycle.svg'),
          mobileOnly: false,
        },
        {
          title: "navbar.paymentInfo",
          link: "/payment-information/",
          img: require('@/assets/img/payment-card.svg'),
          mobileOnly: false,
        }
      ],
      user3Menu: [
        {
          title: "navbar.membersDirectory",
          link: "/dashboard/",
          img: require('@/assets/img/members.svg'),
          mobileOnly: true,
        },
        {
          img: require('@/assets/img/car-history.svg'),
          title: "navbar.carHistoryReport",
          link: process.env.VUE_APP_VIN_REPORTS_LINK,
          mobileOnly: true,
        },
        {
          img: require('@/assets/img/tabler_recycle.svg'),
          title: "navbar.coreBuying",
          link: "/core-buying-service/",
          mobileOnly: true,
        },
      ],
      websiteServicesLink: process.env.VUE_APP_WEBSITE_SERVICES_LINK,
      inventoryUrl: process.env.VUE_APP_INVENTORY_URL,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      roomList: "members/getRoomList",
      userServices: "user/getUserServices",
    }),
    currentRouteName() {
      return this.$route.path;
    },
    filteredMenus() {
      return this.$vuetify.breakpoint.width > 991
        ? this.menus.filter(item => !item.mobileOnly)
        : this.menus;
    },
    user3filteredMenus() {
      return this.$vuetify.breakpoint.width > 991
        ? this.user3Menu.filter(item => !item.mobileOnly)
        : this.user3Menu;
    },
  },

  mounted() {
    this.room = localStorage.getItem("room")
      ? localStorage.getItem("room")
      : null;
    this.getRooms();
    document.getElementById("participantCount").innerText =
      sessionStorage.getItem("onlineUsers")
        ? sessionStorage.getItem("onlineUsers")
        : this.user.online_users_count;
   //this.newsTotalUnread();
    this.$root.$on("updateUnreadnews", () => {
      setTimeout(() => {
        this.newsTotalUnread();
      }, 500);
    });
  },

  methods: {
    ...mapActions({
      logout: "user/logout",
      getRooms: "members/getRoomList",
    }),
    changeRoom() {
      localStorage.setItem("room", this.room);
      location.reload();
    },
    toggleBodyClass() {
      document.body.classList.toggle('sidebar-active');
    },
    removeBodyClass() {
      document.body.classList.remove('sidebar-active');
    },

    conformDailogOpen(nextLink) {
      if (this.$route.name == "BillingInvoiceCreate") {
        this.conformDailog = true;
        this.link = nextLink;
      } else {
        if (nextLink.includes("http") || nextLink.includes("www")) {
          window.open(nextLink, '_blank');
        } else {
          this.$router.push(nextLink);
        }
      }
    },

    isActiveRoute(item) {
      return (
        item.title.toLowerCase() === `navbar.${this.$route.name}`.toLowerCase() ||
        (item.title === 'navbar.marketingCampaign' &&
          ['MarketingCampaign', 'MarketingPhone', 'MarketingEmail', 'AddCampaign', 'HistoryCampaign'].includes(this.$route.name)) ||
        (item.title === 'navbar.hotlineNews' && ['HotlineNews', 'ViewHotlineNews'].includes(this.$route.name))
      );
    },
    async doLogout() {
      try {
        await this.logout();
        sessionStorage.removeItem("onlineUsers");
        window.location.reload();
      } catch (error) {
        console.error(error.message);
      }
    },
  },
};
</script>
